import '../audio/recorder.js';
import UIComponent from "../ui/component.js";
import api from '../api/index.js';

export class UploadLinkForm extends UIComponent {

    init() {
        this.reset();

        this.submitBtn.on('click', (e) => {
            e.preventDefault();
            this.startUploadByLink()
        });

        this.link.on('keypress', (keyPressEvent) => {
            if (keyPressEvent.key !== 'Enter') {
                return
            }
            this.startUploadByLink()
        })

    }

    // Возвращает форму в исходное состояние
    reset() {
        this.submitBtn.enable();
        this.notification.element.classList.add('d-none');
    }

    onSuccess(file) {

    }

    onError() {

    }

    startUploadByLink () {
        let url = this.attr('data-url');
        if (!url) {
            return
        }

        this.submitBtn.disable();
        this.notification.element.classList.remove('d-none');

        api.post(url, {
            link: this.link.element.value
        })
            .then((data) => {
                setTimeout(() => { this.checkUploadStatus(this.link.element.value, data.file_id) }, 15000);
            })
            .catch((error) => this.onError(error))
    }

    checkUploadStatus(link, fileId) {
        let checkUrl = this.attr('data-check-url');

        const checkStatus = () => {
            api.post(checkUrl, {link: link, file_id: fileId})
                .then((data) => {
                    // Проверяем, присутствует ли поле status
                    if (data.status) {
                        if (data.status === 'in_progress') {
                            this.submitBtn.disable();
                            this.notification.element.classList.remove('d-none');
                            // Если статус 'in_progress', проверяем снова через 15 секунд
                            setTimeout(checkStatus, 15000);
                        } else {
                            this.reset();
                            this.onSuccess(data);
                        }
                    } else {
                        // Если поле status отсутствует, завершить выполнение
                        this.reset();
                        this.onSuccess(data);
                    }
                })
                .catch((error) => {
                    this.onError(error);
                    setTimeout( () => {
                        window.location.reload();
                    }, 10000)
                });
        };

        // Начинаем проверку статуса
        checkStatus();
    }
}
